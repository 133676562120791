.home-container {
    position: relative;
    display: flex;
}

@media only screen and (max-width: 599px) {
    .home-right > *:not(:first-child) {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1199px) {
    .home-container {
        flex-direction: column;
    }

    .home-left {
        position: fixed;
        overflow-x: hidden;
        overflow-y: auto;
        top: 52px;
        width: 100vw;
        height: 70%;
        padding-left: 25px;
        padding-right: 25px;
    }
    
    .home-right {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 20%;
        padding-left: 25px;
        padding-right: 25px;
    }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
    .home-container {
        margin-top: 30px;
        flex-direction: row;
    }

    .home-left {
        width: 80vw;
        padding-left: 45px;
        padding-right: 45px;
    }
    
    .home-right {
        position: fixed;
        height: 100%;
        right: -25px;
        width: 20vw;
        margin-left: 35px;
        margin-right: 35px;
    }
    
    .home-right > *:not(:first-child) {
        margin-top: 30px;
    }
}