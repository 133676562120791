@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 15px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #F5F5F5;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 15px;
}
.home-search-container {
    position: relative;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
}

.home-book-search-input {
    position: relative;
    height: 80px;
    width: auto;
    text-align: center;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
    border-bottom-style: solid;
    border-width: 1px;
    background: 0%;
}

.home-book-results {
    position: absolute;
    top: 85px;
    width: 100%;
    max-height: 50vh;
    overflow: auto;
    border-left-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: rgb(71, 71, 116);
}

.home-book-single-result {
    font-size: 23px;
    text-align: center;
    background: rgb(255, 255, 255);
}

.home-book-single-result:hover {
    background: rgb(71, 71, 116);
    color: rgb(255, 255, 255);
}

@media only screen and (max-width: 430px) {
    .home-book-search-input {
        font-size: 30px;
    }
}

@media only screen and (min-width: 431px) {
    .home-book-search-input {
        font-size: 45px;
    }
}

@media only screen and (min-width: 768px) {
    .home-book-search-input {
        font-size: 75px;
    }
}
.home-chapter-select {
    margin: 50px auto;
    text-align: center;
    display: flex;
    flex-flow: row;
}

.home-chapter-selector {
    border-radius: 50px;
    height: 30px;
    margin: auto 10px;
}

.home-verses {
    line-height: 30px;
}

.home-verse-single:hover {
    background: rgb(71, 71, 116);
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.home-verse-single-num {
    font-weight: 700;
}
.verse-background {
    min-height: 100vh;
    padding-top: 10vh;
    background: url("http://4.bp.blogspot.com/-p-MYv7vCz9U/UUspNl9_zlI/AAAAAAAAjq8/EiwqDk124fk/s1600/Full+HD+love+Wallpapers+(1).jpg")
    no-repeat center center fixed
    rgba(0, 0, 0, 1.0); /* For WebKit*/ /* Mozilla*/ /* Opera*/
    background-size: cover; /* Generic*/
    background-position: 50% 1%;
}

.verse-container {
    width: 70vw;
    height: 80vh;
    margin-left: 15vw;
    margin-right: 15vw;
    overflow: hidden;
}

.verse-text {
    width: 100%;
    height: 80%;
    padding: 30px 50px;
    text-align: center;
    display: flex;
    align-items: center;
    border-style: none;
    border-radius: 20px;
    background: rgba(20, 20, 20, 0.5);
    color: white;
    font-family: "georgia";
}

.verse-text-fitter {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.verse-location {
    width: 100%;
    height: 20%;
    text-align: right;
    color: white;
    font-size: 100%;
}
.popup-status {
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    border-color: rgb(50, 71, 187);
    color: rgb(50, 71, 187);
    padding: 5px 0;
}

.popup-status span {
    font-weight: 500;
}

/* Below 1200 pixels */
@media only screen and (max-width: 1199px) {
    .popup-status {
        display: none;
    }

    .popup-status span {
        display: none;
    }
}
.history-container p {
    font-weight: 400;
}

.history-container p:hover {
    font-weight: 500;
    cursor: pointer;
}

.history-instructions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #343a40;
    color: white;
    height: 100%;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    padding: 5px;
}

.history-instructions h3 {
    text-align: center;
}

.history-instructions ul {
    padding: 0 0 0 20px;
}

@media only screen and (max-width: 599px) {
    .history-instructions {
        font-size: 12px;
        width: 100%;
    }

    .history-instructions h3 {
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    .history-instructions {
        width: 70%;
    }
}

/* Below 1200 pixels */
@media only screen and (max-width: 1199px) {
    .history-container {
        height: 100%;
        overflow-y: hidden;
    }

    .history-items {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: flex-start;
        overflow-y: auto;
    }

    .history-container p {
        min-height: 20px;
        width: auto;
        padding: 2px 4px;
        margin: 5px;
        border-style: solid;
        border-width: 1px;
        border-radius: 5px;
        border-color: rgb(83, 98, 182);
        background: rgb(83, 98, 182);
        color: rgb(255, 255, 255);
    }

    .history-instructions {
        margin: 0 auto;
        overflow: visible;
    }
}

@media only screen and (min-width: 1200px) {
    .history-container {
        position: absolute;
        height: calc(100% - 200px);
        width: 100%;
        overflow-y: hidden;
        text-align: center;
        border-style: solid;
        border-width: 1px;
        border-radius: 8px;
        border-color: rgb(50, 71, 187);
        color: rgb(50, 71, 187);
    }

    .history-items {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    .history-instructions {
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
}
.navbar-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #343a40;
}

.navbar-ul li {
    float: left;

}

.navbar-ul li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.navbar-ul li button {
    display: block;
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    border-style: none;
}

.navbar-ul li a:hover:not(.navbar-active),
.navbar-ul li button:hover:not(.navbar-active) {
    background-color: #111;
}

.navbar-active {
    background-color: rgb(83, 98, 182);
}

.navbar-icon {
    vertical-align: -6px;
    margin-right: 2px;
}

.navbar-menu-button {
    display: none;
}

@media screen and (max-width: 600px) {
    .navbar-ul li:not(:first-child) {display: none;}
    .navbar-ul li.navbar-menu-button {
      float: right;
      display: block;
    }
}

@media screen and (max-width: 600px) {
    .navbar-ul.responsive {position: relative;}
    .navbar-ul.responsive li.navbar-menu-button {
      position: absolute;
      right: 0;
      top: 0;
    }
    .navbar-ul.responsive li {
      float: none;
      display: block;
      text-align: left;
    }
}
.main-container {
    position: relative;
    z-index: 0;
    background-color: rgb(255, 255, 255);
    min-height: 100vh;
    width: 100%;
}

.children-container {
    margin: 0 auto;
    /* width: 50vw; */
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 1200px) {
    .main-container {
        padding-bottom: 60px;
    }
}

.home-container {
    position: relative;
    display: flex;
}

@media only screen and (max-width: 599px) {
    .home-right > *:not(:first-child) {
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1199px) {
    .home-container {
        flex-direction: column;
    }

    .home-left {
        position: fixed;
        overflow-x: hidden;
        overflow-y: auto;
        top: 52px;
        width: 100vw;
        height: 70%;
        padding-left: 25px;
        padding-right: 25px;
    }
    
    .home-right {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 20%;
        padding-left: 25px;
        padding-right: 25px;
    }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
    .home-container {
        margin-top: 30px;
        flex-direction: row;
    }

    .home-left {
        width: 80vw;
        padding-left: 45px;
        padding-right: 45px;
    }
    
    .home-right {
        position: fixed;
        height: 100%;
        right: -25px;
        width: 20vw;
        margin-left: 35px;
        margin-right: 35px;
    }
    
    .home-right > *:not(:first-child) {
        margin-top: 30px;
    }
}
