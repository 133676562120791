.home-verses {
    line-height: 30px;
}

.home-verse-single:hover {
    background: rgb(71, 71, 116);
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.home-verse-single-num {
    font-weight: 700;
}