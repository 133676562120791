.main-container {
    position: relative;
    z-index: 0;
    background-color: rgb(255, 255, 255);
    min-height: 100vh;
    width: 100%;
}

.children-container {
    margin: 0 auto;
    /* width: 50vw; */
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 1200px) {
    .main-container {
        padding-bottom: 60px;
    }
}
