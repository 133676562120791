.popup-status {
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    border-color: rgb(50, 71, 187);
    color: rgb(50, 71, 187);
    padding: 5px 0;
}

.popup-status span {
    font-weight: 500;
}

/* Below 1200 pixels */
@media only screen and (max-width: 1199px) {
    .popup-status {
        display: none;
    }

    .popup-status span {
        display: none;
    }
}