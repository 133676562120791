.home-search-container {
    position: relative;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
}

.home-book-search-input {
    position: relative;
    height: 80px;
    width: auto;
    text-align: center;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
    border-bottom-style: solid;
    border-width: 1px;
    background: 0%;
}

.home-book-results {
    position: absolute;
    top: 85px;
    width: 100%;
    max-height: 50vh;
    overflow: auto;
    border-left-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: rgb(71, 71, 116);
}

.home-book-single-result {
    font-size: 23px;
    text-align: center;
    background: rgb(255, 255, 255);
}

.home-book-single-result:hover {
    background: rgb(71, 71, 116);
    color: rgb(255, 255, 255);
}

@media only screen and (max-width: 430px) {
    .home-book-search-input {
        font-size: 30px;
    }
}

@media only screen and (min-width: 431px) {
    .home-book-search-input {
        font-size: 45px;
    }
}

@media only screen and (min-width: 768px) {
    .home-book-search-input {
        font-size: 75px;
    }
}